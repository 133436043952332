import type { Dayjs } from "dayjs"
import dayjs from "dayjs"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type I18n = any

export function isValidDate(date: unknown): boolean {
  return date instanceof Date && !isNaN(date.valueOf())
}

export function toIsoDate(date: string | number | Date | Dayjs | null | undefined): string | null {
  const valid = isValidDate(dayjs(date).toDate())

  if (!valid) {
    return null
  }

  return date ? dayjs(date).format("YYYY-MM-DD") : null
}

export function dateToReadableTimePast($i18n: I18n, fromDate: string | Date) {
  const minutes = dayjs().diff(dayjs(fromDate), "minutes")
  const hours = dayjs().diff(dayjs(fromDate), "hours")
  const days = dayjs().diff(dayjs(fromDate), "days")
  const weeks = dayjs().diff(dayjs(fromDate), "weeks")
  const months = dayjs().diff(dayjs(fromDate), "months")

  if (months) {
    return $i18n.t("lastLoginMonths", months)
  } else if (weeks) {
    return $i18n.t("lastLoginWeeks", weeks)
  } else if (days) {
    return $i18n.t("lastLoginDays", days)
  } else if (hours) {
    return $i18n.t("lastLoginHours", hours)
  } else {
    return $i18n.t("lastLoginMinutes", minutes)
  }
}

export function getDateAtTime(date: string, hours: number, minutes: number, seconds: number, milliseconds: number) {
  const newDate = new Date(date)
  newDate.setHours(hours, minutes, seconds, milliseconds)
  return newDate
}
